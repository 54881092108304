import React from "react"
import {ParallaxProvider} from "react-scroll-parallax"
import CheckWebpFeature from "./src/components/common/ggfx-client/module/webp-checker";
CheckWebpFeature();

const windowGlobal = typeof window !== "undefined" && window
export { wrapRootElement } from "./src/apollo/wrap-root-element";
export const wrapPageElement = ({ element, props }) => {
  return <ParallaxProvider>{element}</ParallaxProvider>
}

export const onRouteUpdate = ({ location }) => {
  // property alert was sent with property-to-rent as base url so replace this here to it got to 404 page
  let pathname = window.location.pathname;
  if (pathname.startsWith("/property-to-rent")) {
    pathname = pathname.replace('property-to-rent', 'property-for-rent')
    window.location.replace(pathname);
  }
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {

  // Avoid to scroll to top when next page trigger click
  // But this feature should condiser with details page back scroll too
  if (location.pathname.match(/property\/for-sale/)) {
    return false;
  }
  if (location.pathname.match(/property\/for-rent/)) {
    return false;
  }

  if (location.pathname.match(/property-map\/for-sale/)) {
    return false;
  }
  if (location.pathname.match(/property-map\/for-rent/)) {
    return false;
  }

  return true;
};